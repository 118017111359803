<template>
    <div>
        <ModalCommon :id="`configModalCommonPreview-${config?.id ?? ''}`" :config="config" :isView="true" @close="handleClose()">
            <form class="needs-validation" autocomplete="off">
                <div :style="`height: ${loading ? '78vh' : '100%'}`">
                    <div class="form-group" v-if="!loading">
                        <div v-html="dataTemplateDetail.content"></div>
                    </div>
                    <div v-else style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
                        <LoadingIcon />
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

export default {
    name: 'PopUpWinningDistribution',
    components: {
        ModalCommon,
        LoadingIcon
    },
    props: {
        dataTemplateDetail: {
            required: true,
            type: Object,
            default: () => {
                return {
                    content: ''
                };
            }
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        config: {
            type: Object,
            default: () => null
        }
    },
    methods: {
        handleClose() {
            this.config.model = false;
        }
    }
};
</script>

<style lang="scss" scoped></style>
