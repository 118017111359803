<template>
    <div>
        <ModalCommon :id="`configModalCommon-${id}`" :config="config" @save="handleConditionFMT()" @close="handleClear()">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.secretariatStartDate') }}</label>
                    <div class="col-sm-8">
                        {{ formatDateCommon(dataForm?.campaign_start_date, 'dateTimeJP') ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.campaignEndDate') }}</label>
                    <div class="col-sm-8">
                        {{ formatDateCommon(dataForm?.campaign_end_date, 'dateTimeJP') ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.secretariatType') }}</label>
                    <div class="col-sm-8">
                        {{ joinValue(dataForm?.categories) ?? '' }}
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.deliveryCycle') }}</label>
                    <div class="col-sm-8">
                        <Multiselect :id="`select-cycle`" :value.sync="dataForm.cycle" :options="dataCycle" :config.sync="configFieldCycle" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.closingDate') }}</label>
                    <div class="col-sm-8">
                        <Multiselect
                            :id="`select-closing-date`"
                            :value.sync="dataForm.closing_date"
                            :options="dataDayOfWeek"
                            :config.sync="configFieldClosingDate"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.reportDate') }}</label>
                    <div class="col-sm-8">
                        <Multiselect
                            :id="`select-report-date`"
                            :value.sync="dataForm.report_date"
                            :options="dataDayOfWeek"
                            :config.sync="configFieldReportDate"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.skipTheFirstWeekClosing') }}</label>
                    <div class="col-sm-8">
                        <InputCheckBox
                            :model.sync="dataForm.skip_the_first_week_closing"
                            :id="`check-skip_the_first_week_closing`"
                            :value="dataForm.skip_the_first_week_closing"
                            :label="``"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">{{ $t('document.includeItOneWeekBefore') }}</label>
                    <div class="col-sm-8">
                        <InputCheckBox
                            :model.sync="dataForm.include_it_one_week_before"
                            :id="`check-include_it_one_week_before`"
                            :value="dataForm.include_it_one_week_before"
                            :label="``"
                        />
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import Multiselect from '@/components/Multiselect/main.vue';
import ModalCommon from '@/components/Modal/main.vue';

import { cycleFMT, DayOfWeek } from '@/views/pages/documents/main.js';
import { formatDateCommon } from '@/utils/format';
import { InputCheckBox } from '@/components/Input';
import { configs } from '../../main';

export default {
    name: 'ModalCondition',
    components: {
        Multiselect,
        ModalCommon,
        InputCheckBox
    },
    props: {
        id: {
            required: true
        },
        dataCondition: {
            type: Object,
            default: () => null
        },
        config: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            dataForm: this.dataCondition,
            dataFormClone: JSON.parse(JSON.stringify(this.dataCondition)),
            dataCycle: cycleFMT,
            dataDayOfWeek: DayOfWeek,
            configFieldCycle: configs.configFieldCycle,
            configFieldClosingDate: configs.configFieldClosingDate,
            configFieldReportDate: configs.configFieldReportDate
        };
    },
    methods: {
        handleConditionFMT() {
            this.$emit('update:dataCondition', this.dataForm);
            this.config.model = false;
        },

        handleClear() {
            this.dataForm = this.dataFormClone;

            this.$emit('update:dataCondition', this.dataForm);
            this.config.model = false;
        },

        formatDateCommon(date, type) {
            return formatDateCommon(date, type);
        },

        joinValue(param) {
            if (!param || !param?.length) return '';
            return param.map((o) => o?.category?.value ?? '').join('、');
        }
    },
    watch: {
        dataCondition: {
            handler: function (newdataCondition) {
                this.dataForm = newdataCondition;
            },
            immediate: true,
            deep: true
        },

        'config.model': {
            handler: function (valShow) {
                if (valShow) {
                    this.dataFormClone = JSON.parse(JSON.stringify(this.dataForm));
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
