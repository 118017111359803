import i18n from '@/i18n';
import moment from 'moment';

import { validateAllField } from '@/utils/validate';

function handleValidate(initForm) {
    let check = validateAllField(initForm);
    const startDate = moment(initForm.configFieldStartDate.valueSync, 'YYYY-MM-DD HH:mm');
    const endDate = moment(initForm.configFieldEndDate.valueSync, 'YYYY-MM-DD HH:mm');
    if (!endDate.isSameOrAfter(startDate)) {
        check = false;
        initForm.configFieldEndDate.error = true;
        initForm.configFieldEndDate.errorText = i18n.t('validateField.compareDate');
    }
    return check;
}

function validateEndDate(configField, startDateStr, endDateStr) {
    const startDate = moment(startDateStr, 'YYYY-MM-DD HH:mm');
    const endDate = moment(endDateStr, 'YYYY-MM-DD HH:mm');
    if (!endDate.isSameOrAfter(startDate)) {
        configField.error = true;
        configField.errorText = i18n.t('validateField.compareDate');
    }
}

function initForm() {
    return {
        start_date_cp_full_date: '',
        start_date_cp_full_time: '',
        configFieldStartDate: {
            valueSync: '',
            error: false,
            typesValidate: ['requiredDate', 'dateTime'],
            errorField: i18n.t('sheduleFile.startDateCP'),
            errorText: ''
        },

        end_date_cp_full_date: '',
        end_date_cp_full_time: '',
        configFieldEndDate: {
            valueSync: '',
            error: false,
            typesValidate: ['requiredDate', 'dateTime'],
            errorField: i18n.t('sheduleFile.endDateCP'),
            errorText: ''
        },

        date_shipping_year: '',
        date_shipping_month: '',
        date_shipping_day: '',
        date_shipping_full_date: '',
        configFieldDateShipping: {
            valueSync: '',
            error: false,
            typesValidate: ['requiredDate', 'date'],
            errorField: i18n.t('sheduleFile.dateShipping'),
            errorText: ''
        },

        end_date_secretariat_full_date: '',
        end_date_secretariat_full_time: '',
        configFieldEndDateSecretariat: {
            valueSync: '',
            error: false,
            typesValidate: ['requiredDate', 'dateTime'],
            errorField: i18n.t('sheduleFile.endDateSecretariat'),
            errorText: ''
        },

        type_application: [],
        listApplication: []
    };
}

export { handleValidate, initForm, validateEndDate };
