<template>
    <div>
        <ModalCommon :id="`configModalCommonPreview-${config?.id ?? ''}`" :config="config" @save="setConditionRule()" @close="handleClose()">
            <form class="needs-validation" autocomplete="off">
                <div :style="`height: ${loading ? '78vh' : '100%'}`">
                    <b-tabs v-if="!loading" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                        <b-tab active class="border-0" @click="handleChangeTab()">
                            <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="">条件設定</span>
                            </template>

                            <div style="height: 600px; overflow-y: auto; overflow-x: hidden">
                                <div class="form-group row">
                                    <label class="col-sm-3">エンドクライアント <span class="text-danger">*</span></label>
                                    <div class="col-sm-9 pl-0">
                                        <Multiselect
                                            :id="`select-campaign`"
                                            :value.sync="dataForm.client"
                                            :options="masterClients"
                                            :config.sync="configSelectClient"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-3">キャンペーン種別</label>
                                    <div class="col-sm-8">
                                        <div class="d-flex">
                                            <div v-for="item in masterCampaignType" :key="item.id + 's'">
                                                <InputRadio
                                                    class="mr-4"
                                                    :model.sync="campaignT"
                                                    :id="`check${item.id}`"
                                                    :value="item.value"
                                                    :label="item.value"
                                                    @change="changeCampaign()"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr v-if="masterRuleGeneral && masterRuleGeneral.length > 0">
                                                    <td :rowspan="masterRuleGeneral && masterRuleGeneral.length">共通</td>
                                                    <td @mouseup="handleSelectTextTab1(masterRuleGeneral[0].id)">
                                                        {{ masterRuleGeneral[0].rule }}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <InputCheckBox
                                                                :model.sync="masterRuleGeneralCheck.rules"
                                                                :id="`checkard${masterRuleGeneral[0].id}`"
                                                                :value="masterRuleGeneral[0].id"
                                                                :label="``"
                                                                @change="handleGetDetailConditionsTab1(masterRuleGeneralCheck.rules)"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <template v-for="(item, k) in masterRuleGeneral">
                                                    <tr v-if="k > 0" :key="item.id">
                                                        <td>
                                                            <div @mouseup="handleSelectTextTab1(item.id)">
                                                                {{ item.rule }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <InputCheckBox
                                                                    :model.sync="masterRuleGeneralCheck.rules"
                                                                    :id="`checkard${item.id}`"
                                                                    :value="item.id"
                                                                    :label="``"
                                                                    @change="handleGetDetailConditionsTab1(masterRuleGeneralCheck.rules)"
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>

                                                <template v-if="campaignType == 'クローズ'">
                                                    <tr v-if="masterRuleClose && masterRuleClose.length > 0">
                                                        <td :rowspan="masterRuleClose.length">クローズ</td>
                                                        <td>{{ masterRuleClose[0].rule }}</td>
                                                        <td>
                                                            <div>
                                                                <InputCheckBox
                                                                    :model.sync="masterRuleCloseCheck.rules"
                                                                    :id="`checkar${masterRuleClose[0].id}`"
                                                                    :value="masterRuleClose[0].id"
                                                                    :label="``"
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <template v-for="(item, k) in masterRuleClose">
                                                        <tr v-if="k > 0" :key="item.id">
                                                            <td>
                                                                <div v-html="item.rule"></div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <InputCheckBox
                                                                        :model.sync="masterRuleCloseCheck.rules"
                                                                        :id="`checkar${item.id}`"
                                                                        :value="item.id"
                                                                        :label="``"
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        class="col-sm-5 ml-3 mr-2 py-2"
                                        style="word-wrap: break-word; height: 500px; overflow-y: auto; border: #efefef 1px solid"
                                    >
                                        <div
                                            v-for="(conditionCheckedTab1, idx) in childConditionAfterCheckedTab1"
                                            :key="idx"
                                            class="mb-3"
                                            :style="`background-color:${
                                                conditionCheckedTab1.backgroundColor ? conditionCheckedTab1.backgroundColor : ''
                                            };color:${
                                                conditionCheckedTab1.color ? conditionCheckedTab1.color : ''
                                            };border-bottom: 1px solid #b7b7b74d;`"
                                        >
                                            <div class="text-center">
                                                <p class="font-weight-bold pt-1" style="font-size: 15px">
                                                    {{ conditionCheckedTab1.textParent }}
                                                </p>
                                            </div>
                                            <div v-for="(item, k) in conditionCheckedTab1.details" :key="k">
                                                <p class="p-2">{{ item.detail }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab class="border-0" @click="handleChangeTab()">
                            <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="">応募種別関連設定</span>
                            </template>

                            <div style="height: 600px; overflow-y: auto; overflow-x: hidden">
                                <div class="row form-group">
                                    <label class="col-sm-3">応募種別 </label>
                                    <div class="col-sm-9">
                                        <div class="d-flex flex-wrap">
                                            <template>
                                                <div v-for="item in listTab2Var" :key="item.id + 'sss'">
                                                    <InputCheckBox
                                                        class="mr-4"
                                                        :model.sync="dataRuleTabVar"
                                                        :id="`checka${item.id}`"
                                                        :value="item.id"
                                                        :label="item.value"
                                                        @change="handleChangeCategoryTab2(dataRuleTabVar)"
                                                    />
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <table class="table table-bordered">
                                        <template>
                                            <tbody v-for="jtem in listTab2Var" :key="jtem.id">
                                                <template v-if="dataRuleTabVar.includes(jtem.id)">
                                                    <tr v-if="dataRuleTab2Var[jtem.id] && dataRuleTab2Var[jtem.id][0]">
                                                        <td :rowspan="dataRuleTab2Var[jtem.id].length">
                                                            {{ jtem.value }}
                                                        </td>
                                                        <td @mouseup="handleSelectTextTab2(dataRuleTab2Var[jtem.id][0].id)">
                                                            {{ dataRuleTab2Var[jtem.id][0].rule }}
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <InputCheckBox
                                                                    :model.sync="dataRuleTab2CheckVar[jtem.id][0].rules"
                                                                    :id="`checka${dataRuleTab2Var[jtem.id][0].id}`"
                                                                    :value="dataRuleTab2Var[jtem.id][0].id"
                                                                    :label="``"
                                                                    @change="
                                                                        handleGetDetailConditionsTab2(
                                                                            dataRuleTab2CheckVar[jtem.id][0],
                                                                            'model_checked'
                                                                        )
                                                                    "
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <template v-for="(item, k) in dataRuleTab2Var[jtem.id]">
                                                        <tr v-if="k > 0" :key="item.id">
                                                            <td>
                                                                <div @mouseup="handleSelectTextTab2(item.id)">
                                                                    {{ item.rule }}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    <InputCheckBox
                                                                        :model.sync="dataRuleTab2CheckVar[jtem.id][k].rules"
                                                                        :id="`checka${item.id}`"
                                                                        :value="item.id"
                                                                        :label="``"
                                                                        @change="
                                                                            handleGetDetailConditionsTab2(
                                                                                dataRuleTab2CheckVar[jtem.id][k],
                                                                                'model_checked'
                                                                            )
                                                                        "
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </template>
                                    </table>
                                    <div
                                        class="col-sm-5 ml-3 mr-2 py-2"
                                        style="word-wrap: break-word; height: 500px; overflow-y: auto; border: #efefef 1px solid"
                                    >
                                        <div
                                            v-for="(conditionCheckedTab2, idx) in childConditionAfterCheckedTab2"
                                            :key="idx"
                                            class="mb-3"
                                            :style="`background-color:${
                                                conditionCheckedTab2.backgroundColor ? conditionCheckedTab2.backgroundColor : ''
                                            };color:${
                                                conditionCheckedTab2.color ? conditionCheckedTab2.color : ''
                                            };border-bottom: 1px solid #b7b7b74d;`"
                                        >
                                            <div class="text-center">
                                                <p class="font-weight-bold pt-1" style="font-size: 15px">
                                                    {{ conditionCheckedTab2.textParent }}
                                                </p>
                                            </div>
                                            <div v-for="(item, k) in conditionCheckedTab2.details" :key="k">
                                                <p class="p-2">
                                                    {{ item.detail }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                    <div v-else style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
                        <LoadingIcon />
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import ModalCommon from '@/components/Modal/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

import { validateField } from '@/utils/validate';
import { InputRadio, InputCheckBox } from '@/components/Input';
import { configs } from '../../main';

export default {
    name: 'Modal-Condition-Other',
    components: {
        ModalCommon,
        LoadingIcon,
        Multiselect,
        InputRadio,
        InputCheckBox
    },
    props: {
        dataRuleTab: {
            type: Array,
            default: () => []
        },
        dataRuleTab2: {
            default: () => {}
        },
        dataRuleTab2Check: {
            required: true
        },
        listTab2: {
            required: true,
            type: Array,
            default: () => []
        },
        childConditionAfterCheckedTab1: {
            required: true,
            type: Array,
            default: () => []
        },
        childConditionAfterCheckedTab2: {
            required: true,
            type: Array,
            default: () => []
        },
        masterCampaignType: {
            required: true,
            type: Array,
            default: () => []
        },
        masterClients: {
            required: true,
            type: Array,
            default: () => []
        },
        masterRuleGeneral: {
            required: true,
            type: Array,
            default: () => []
        },
        masterRuleGeneralCheck: {
            required: true,
            type: Object,
            default: () => {
                return {
                    rules: []
                };
            }
        },
        masterRuleClose: {
            required: true,
            type: Array,
            default: () => []
        },
        masterRuleCloseCheck: {
            required: true,
            type: Object,
            default: () => {
                return {
                    rules: []
                };
            }
        },
        campaignType: {
            default: () => null
        },
        formOther: {
            default: () => null
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        config: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            configSelectClient: configs.configSelectClient,
            campaignT: this.campaignType,
            dataForm: this.formOther,
            dataRuleTabVar: this.dataRuleTab,
            dataRuleTab2Var: this.dataRuleTab2,
            dataRuleTab2CheckVar: this.dataRuleTab2Check,
            listTab2Var: this.listTab2
        };
    },
    watch: {
        formOther: {
            handler: function (newFormOther) {
                this.dataForm = newFormOther;
            },
            immediate: true
        },
        dataForm: {
            handler: function (newDataForm) {
                this.$emit('update:formOther', newDataForm);
            },
            immediate: true
        },

        dataRuleTab: {
            handler: function (newData) {
                this.dataRuleTabVar = newData;
            },
            immediate: true
        },

        dataRuleTab2: {
            handler: function (newData) {
                this.dataRuleTab2Var = newData;
            },
            immediate: true
        },

        dataRuleTab2Check: {
            handler: function (newData) {
                this.dataRuleTab2CheckVar = newData;
            },
            immediate: true,
            deep: true
        },

        listTab2: {
            handler: function (newData) {
                this.listTab2Var = newData;
            },
            immediate: true,
            deep: true
        },

        campaignType: {
            handler: function (newData) {
                this.campaignT = newData;
            },
            immediate: true,
            deep: true
        },

        dataRuleTabVar: {
            handler: function (newData) {
                this.$emit('update:dataRuleTab', newData);
            },
            immediate: true
        }
    },

    methods: {
        handleClose() {
            this.config.model = false;
            this.configSelectClient.error = false;
            this.$emit('close');
        },
        changeCampaign() {
            this.$emit('update:campaignType', this.campaignT);
            this.$emit('changeCampaign');
        },

        handleSelectTextTab1(idText) {
            this.$emit('selectTextTab1', idText);
        },

        handleGetDetailConditionsTab1(listIdCheck) {
            this.$emit('getDetailConditionsTab1', listIdCheck);
        },

        handleChangeCategoryTab2(listCategory) {
            this.$emit('changeCategoryTab2', listCategory);
        },

        handleSelectTextTab2(idText) {
            this.$emit('selectTextTab2', idText);
        },

        setConditionRule() {
            let check = validateField(this.configSelectClient, true);
            if (!check) return;
            this.config.model = false;
            this.dataForm.rulesCheckTab1 = [...new Set([...this.dataForm.rulesCheckTab1, ...this.masterRuleCloseCheck.rules])];
            this.$emit('saveConditionRule', this.dataForm);
        },

        handleChangeTab() {
            this.childConditionAfterCheckedTab1.forEach((item) => {
                item.backgroundColor = '';
                item.color = '';
            });
            this.childConditionAfterCheckedTab2.forEach((item) => {
                item.backgroundColor = '';
                item.color = '';
            });

            this.$emit('update:childConditionAfterCheckedTab1', this.childConditionAfterCheckedTab1);
            this.$emit('update:childConditionAfterCheckedTab2', this.childConditionAfterCheckedTab2);
        },

        handleGetDetailConditionsTab2(listIdCheck, typeHandle) {
            this.$emit('getDetailConditionsTab2', listIdCheck, typeHandle);
        }
    }
};
</script>

<style lang="scss" scoped></style>
