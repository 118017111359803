<template>
    <div>
        <ModalCommon :id="`configModalCommon-${config?.id}`" :config="config" @save="handleSubmit()" @close="handleCloseModal()">
            <div>
                <form class="needs-validation" autocomplete="off" @submit.prevent="handleSubmit" @keydown.enter.prevent>
                    <div class="form-group row mb-3">
                        <label class="col-sm-3">{{ $t('sheduleFile.startDateCP') }} <span class="text-danger">*</span></label>
                        <div class="col-sm-9 pl-0">
                            <DateTime
                                :id="`select-campaign-start-date-time`"
                                :full_date.sync="initForm.start_date_cp_full_date"
                                :full_time.sync="initForm.start_date_cp_full_time"
                                :config.sync="initForm.configFieldStartDate"
                                @blur="handleValidateField(initForm.configFieldStartDate)"
                                @close="handleValidateField(initForm.configFieldStartDate)"
                            >
                                <div v-if="initForm.configFieldStartDate.error" class="invalid-feedback d-block">
                                    <span>{{ initForm.configFieldStartDate.errorText }}</span>
                                </div>
                            </DateTime>
                        </div>
                    </div>

                    <div class="form-group row mb-3">
                        <label class="col-sm-3">{{ $t('sheduleFile.endDateCP') }} <span class="text-danger">*</span></label>
                        <div class="col-sm-9 pl-0">
                            <DateTime
                                :id="`select-campaign-end-date-time`"
                                :full_date.sync="initForm.end_date_cp_full_date"
                                :full_time.sync="initForm.end_date_cp_full_time"
                                :config.sync="initForm.configFieldEndDate"
                                @blur="handleValidateField(initForm.configFieldEndDate, 'compare-date')"
                                @close="handleValidateField(initForm.configFieldStartDate)"
                            >
                                <div v-if="initForm.configFieldEndDate.error" class="invalid-feedback d-block">
                                    <span>{{ initForm.configFieldEndDate.errorText }}</span>
                                </div>
                            </DateTime>
                        </div>
                    </div>

                    <div class="form-group row mb-3">
                        <label class="col-sm-3">{{ $t('sheduleFile.dateShipping') }} <span class="text-danger">*</span></label>
                        <div class="col-sm-9 pl-0">
                            <DateNormal
                                :id="`select-date_shipping`"
                                :year.sync="initForm.date_shipping_year"
                                :month.sync="initForm.date_shipping_month"
                                :day.sync="initForm.date_shipping_day"
                                :full_date.sync="initForm.date_shipping_full_date"
                                :config.sync="initForm.configFieldDateShipping"
                                @blur="handleValidateField(initForm.configFieldDateShipping)"
                            >
                                <div v-if="initForm.configFieldDateShipping.error" class="invalid-feedback d-block">
                                    <span>{{ initForm.configFieldDateShipping.errorText }}</span>
                                </div>
                            </DateNormal>
                        </div>
                    </div>

                    <div class="form-group row mb-3">
                        <label class="col-sm-3">{{ $t('sheduleFile.endDateSecretariat') }} <span class="text-danger">*</span></label>
                        <div class="col-sm-9 pl-0">
                            <DateTime
                                :id="`select-end_date_secretariat`"
                                :full_date.sync="initForm.end_date_secretariat_full_date"
                                :full_time.sync="initForm.end_date_secretariat_full_time"
                                :config.sync="initForm.configFieldEndDateSecretariat"
                                @blur="handleValidateField(initForm.configFieldEndDateSecretariat)"
                                @close="handleValidateField(initForm.configFieldStartDate)"
                            >
                                <div v-if="initForm.configFieldEndDateSecretariat.error" class="invalid-feedback d-block">
                                    <span>{{ initForm.configFieldEndDateSecretariat.errorText }}</span>
                                </div>
                            </DateTime>
                        </div>
                    </div>

                    <div class="form-group row mb-3">
                        <label class="col-sm-3">{{ $t('sheduleFile.typeApplication') }}</label>
                        <div class="col-sm-9">
                            <div class="d-flex flex-wrap">
                                <div v-for="item in initForm.listApplication" :key="item.id + 's'">
                                    <InputCheckBox
                                        class="mr-3 mb-2"
                                        :model.sync="initForm.type_application"
                                        :id="`check-type_application${item.id}`"
                                        :value="item.id"
                                        :label="item.value"
                                        @change="handleChangeTypeApplication(item.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row mb-3">
                        <div class="table-responsive" style="height: 390px; overflow-y: auto">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th colspan="1" rowspan="3">
                                            {{ $t('sheduleFile.conditionExport') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(itemT1, indexT1) in listDataType1">
                                        <tr v-for="(ws, idx) in itemT1.works" :key="`${indexT1}-${idx}-type1`">
                                            <td v-if="idx === 0" :rowspan="itemT1.works.length">
                                                {{ itemT1.title }}
                                            </td>
                                            <td>
                                                <div class="row-data-small">
                                                    {{ ws.work_title ? ws.work_title : '' }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="row-data-small">
                                                    <InputCheckBox
                                                        :model.sync="ws.checked"
                                                        :id="`check-checked${indexT1}-${idx}`"
                                                        :value="ws.checked"
                                                        :label="``"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>

                                    <template v-for="(itemT2, indexT2) in listDataType2">
                                        <tr v-for="(ws, idx) in itemT2.works" :key="`${indexT2}-${idx}-type2`">
                                            <td v-if="idx === 0" :rowspan="itemT2.works.length">
                                                {{ itemT2.title }}
                                            </td>
                                            <td>
                                                <div class="row-data-small">
                                                    {{ ws.work_title ? ws.work_title : '' }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="row-data-small">
                                                    <InputCheckBox
                                                        :model.sync="ws.checked"
                                                        :id="`check-indexT2${indexT2}-${idx}`"
                                                        :value="ws.checked"
                                                        :label="``"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import DateTime from '@/components/DateCustoms/DateTime/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
import ModalCommon from '@/components/Modal/main.vue';

import { InputCheckBox } from '@/components/Input';
import { handleValidate, initForm, validateEndDate } from './index';
import { formatDateCommon } from '@/utils/format';
import { validateField } from '@/utils/validate';

export default {
    name: 'PopUpSchedule',
    props: {
        config: {
            type: Object,
            required: false
        },
        masterDataType1: {
            default: () => []
        },
        masterDataPlatform: {
            default: () => []
        }
    },
    components: {
        DateTime,
        DateNormal,
        ModalCommon,
        InputCheckBox
    },
    data() {
        return {
            initForm: initForm(),
            listDataType1: this.masterDataType1,
            listDataPlatform: this.masterDataPlatform,
            listDataType2: [],
            isLoading: false
        };
    },
    methods: {
        handleValidateField(configField, action = null) {
            validateField(configField);
            if (action == 'compare-date') {
                validateEndDate(
                    this.initForm.configFieldEndDate,
                    this.initForm.configFieldStartDate.valueSync,
                    this.initForm.configFieldEndDate.valueSync
                );
            }
        },

        handleClearData() {
            this.initForm = initForm();
        },

        handleChangeTypeApplication(id) {
            this.handleDataCommon('change', id);
        },

        handleCloseModal() {
            this.handleClearData();
        },

        handleCheckedApplicationType1() {
            this.listDataType1.forEach((parent) => {
                parent.works.forEach((child) => {
                    child.checked = this.config.listIDWorks.includes(child.id);
                });
            });
        },

        handleDataCommon(type, id) {
            this.listDataType2 = [];
            this.listDataPlatform.forEach((parent) => {
                parent.platforms.forEach((child) => {
                    if (id && !this.initForm.type_application.includes(id)) {
                        if (child.id === id) {
                            const mapData = parent.platforms.map((p) => p.id);
                            const isSome = mapData.some((item) => this.initForm.type_application.includes(item));
                            if (isSome) return;
                            parent.works.forEach((ws) => {
                                ws.checked = false;
                            });
                        }
                    }

                    if (this.initForm.type_application.includes(child.id)) {
                        if (!this.listDataType2.some((item) => item.id === parent.id)) {
                            if (type !== 'change') {
                                parent.works.forEach((ws) => {
                                    ws.checked = this.config.listIDWorks.includes(ws.id);
                                });
                            }
                            this.listDataType2.push(parent);
                        }
                    }
                });
            });
        },

        handleSubmit() {
            let check = handleValidate(this.initForm);
            if (!check) return;
            const checkedType1 = this.listDataType1.map((item) => item.works.filter((ws) => ws.checked).map((ws) => ws.id));
            const checkedType2 = this.listDataType2.map((item) => item.works.filter((ws) => ws.checked).map((ws) => ws.id));

            let objSchedule = {
                parentID: this.config.code,
                titles: [...checkedType1, ...checkedType2].flat(),
                campaign_start_date: this.initForm.configFieldStartDate.valueSync,
                campaign_end_date: this.initForm.configFieldEndDate.valueSync,
                date_shipping: this.initForm.date_shipping_full_date,
                secretariat_end_date: this.initForm.configFieldEndDateSecretariat.valueSync,
                categories: this.initForm.type_application.map((item) => {
                    return {
                        category_id: item
                    };
                })
            };
            this.$emit('update:exportSchedule', objSchedule);
            this.$props.config.model = false;
        }
    },
    watch: {
        config: {
            handler() {
                let handleData = this.config.dataCPType.find((item) => {
                    return item.id === this.config.dataDocument.campaign_type.id;
                });
                this.initForm = {
                    ...this.initForm,
                    start_date_cp_full_time: formatDateCommon(this.config.dataDocument.campaign_start_date, 'dateTime') ?? '',
                    end_date_cp_full_time: formatDateCommon(this.config.dataDocument.campaign_end_date, 'dateTime') ?? '',
                    end_date_secretariat_full_time: formatDateCommon(this.config.dataDocument.secretariat_end_date, 'dateTime') ?? '',
                    start_date_cp_full_date: formatDateCommon(this.config.dataDocument.campaign_start_date, 'date') ?? '',
                    end_date_cp_full_date: formatDateCommon(this.config.dataDocument.campaign_end_date, 'date') ?? '',
                    date_shipping_full_date: formatDateCommon(this.config.dateShipping, 'date') ?? '',
                    end_date_secretariat_full_date: formatDateCommon(this.config.dataDocument.secretariat_end_date, 'date') ?? '',

                    listApplication: handleData.data.map((item) => {
                        return {
                            id: item.id,
                            value: item.value
                        };
                    }),
                    type_application: this.config.dataDocument.categories.map((item) => item.category_id)
                };
                this.handleCheckedApplicationType1();
                this.handleDataCommon('config', null);
            },
            deep: true
        },

        masterDataType1: {
            handler: function (newData) {
                this.listDataType1 = newData;
            },
            immediate: true
        },
        masterDataPlatform: {
            handler: function (newData) {
                this.listDataPlatform = newData;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
