<script>
import { authComputed, campaignMethods, checkPermission, keyMaster, masterMethods } from '@/state/helpers';
import { configs, LISTNotCondition, LISTCondition, replaceQ, replaceA, handMapDataDocument, funcConditionLabel, handleDataAllRule } from './main';
import { showMessage } from '@/utils/messages';
import { InputCheckBox } from '@/components/Input';

import moment from 'moment';
import Layout from '@/views/layouts/main';
import Footer from '@/components/Footer/main.vue';
import appConfig from '@/app.config';
import Multiselect from '@/components/Multiselect/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import HistoryExportDocument from '@/views/pages/documents/TableExportDocument/main.vue';
import PopUpExportSchedule from './popup/schedule/main.vue';
import PopUpFAQ from '@/views/pages/documents/popup/faq/main.vue';
import PopUpCondition from '@/views/pages/documents/popup/condition/main.vue';
import PopUpConditionFMT from '@/views/pages/documents/popup/condition-fmt/main.vue';
import PopUpWinningDistribution from '@/views/pages/documents/popup/winning-distribution/main.vue';
import PopUpConditionOther from '@/views/pages/documents/popup/condition-other/main.vue';
export default {
    page: {
        title: '立ち上げ資料',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        LoadingIcon,
        Multiselect,
        HistoryExportDocument,
        PopUpCondition,
        PopUpConditionFMT,
        PopUpWinningDistribution,
        PopUpFAQ,
        PopUpExportSchedule,
        PopUpConditionOther,
        InputCheckBox
    },
    data() {
        return {
            loading: false,
            activeTab: 0,

            // config
            configSelectCampaign: configs.configSelectCampaign,
            configExportSchedule: configs.configExportSchedule,
            configModalCommonCondition: configs.configModalCommonCondition,
            configModalCommonConditionFMT: configs.configModalCommonConditionFMT,
            configModalCommonPreview: configs.configModalCommonPreview,
            configFaq: configs.configFaq,
            configModalCommonConditionOther: configs.configModalCommonConditionOther,

            listDataMasterCampaign: [],
            listDataMasterRuleGeneral: [],
            listDataMasterRuleGeneralCheck: {
                platform: [],
                rules: []
            },
            listDataMasterRuleClose: [],
            listDataMasterRuleCloseCheck: {
                platform: [],
                rules: []
            },
            dataRuleTab2: {},
            dataRuleTab2Check: [],
            dataRuleTab: [],
            listTab2: [],
            listDataMasterClients: [],
            listDataMasterPartners: [],
            isLoading: false,
            indexActive: '',
            clientOther: null,

            listDataMasterCampaignType: [],
            campaign: null,
            listNotCondition: LISTNotCondition(),
            listCondition: LISTCondition(),
            conditions: {},

            campaignType: '',
            templateDetail: {},
            documentPreviewData: {},

            dataDetailForDocument: null,
            detailBeginForDocument: null,
            objExportSchedule: null,
            modalConditionOtherObj: {},
            modalConditionOtherId: '',
            listChildConditionAfterCheckedTab1: [],
            listChildConditionAfterCheckedTab2: [],
            listIDConditionTab2Checked: [],
            listConditionRuleDetail: [],
            listDataType1: [],
            listDataPlatform: [],
            isLoadingConditionOther: false,
            isLoadingTemplate: false
        };
    },
    watch: {
        campaign: function (newName) {
            if (newName?.id) {
                this.getListMasterCampaignDetail(newName);
                this.objExportSchedule = null;
            }
        },

        objExportSchedule: function (newName) {
            this.listCondition.forEach((item) => {
                item.data.forEach((i) => {
                    if (item.label === this.$t('document.schedule')) {
                        if (i.id === newName?.parentID) {
                            i.condition = newName;
                        }
                    }
                });
            });
        }
    },
    mounted() {
        this.getListMasterCampaign();
        this.getListMasterTemplate();
        this.getAllRule();
        this.getListMasterCampaignType();
        this.getListMasterScheduleFile();
    },
    computed: {
        ...authComputed,

        isBtnExport() {
            if (!Object.keys(this.conditions).length) return true;
            const hasTrueValue = Object.values(this.conditions).some((value) => value === true);
            return !hasTrueValue;
        }
    },
    methods: {
        ...masterMethods,
        ...campaignMethods,

        conditionLabel(label) {
            return funcConditionLabel(label);
        },

        clearDataOther() {
            this.listDataMasterRuleGeneralCheck.rules = [];
            this.listDataMasterRuleCloseCheck.rules = [];
            this.listIDConditionTab2Checked = [];
            this.dataRuleTab = [];
            this.listChildConditionAfterCheckedTab1 = [];
            this.listChildConditionAfterCheckedTab2 = [];
            this.listTab2.forEach((item) => {
                if (this.dataRuleTab.includes(item.id)) {
                    if (this.dataRuleTab2[item.id] && this.dataRuleTab2[item.id].length > 0) {
                        this.dataRuleTab2Check[item.id].forEach((item1) => {
                            item1.rules = [];
                        });
                    }
                }
            });

            if (!this.modalConditionOtherObj || !this.modalConditionOtherObj[this.modalConditionOtherId]) return;
            this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab1 = [];
            this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab2 = [];
            this.modalConditionOtherObj[this.modalConditionOtherId].categories = [];
            this.handleGetDetailConditionsTab1(this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab1);
            this.handleGetDetailConditionsTab2(this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab2, null);
        },

        getListMasterCampaignType() {
            this.loading = true;
            this.listMaster(keyMaster['campaign.type']).then((data) => {
                this.listDataMasterCampaignType = data;
                this.loading = false;
            });
        },

        getAllRule() {
            this.loading = true;
            this.listMasterRule1().then((data) => {
                if (data.code !== 200) return;
                const resultDataRule = handleDataAllRule(
                    data,
                    this.listConditionRuleDetail,
                    this.listDataMasterRuleGeneral,
                    this.listDataMasterRuleGeneralCheck,
                    this.listDataMasterRuleClose,
                    this.listDataMasterRuleCloseCheck,
                    this.dataRuleTab2,
                    this.dataRuleTab2Check
                );

                this.listConditionRuleDetail = resultDataRule.listConditionRuleDetail;
                this.listDataMasterRuleGeneral = resultDataRule.listDataMasterRuleGeneral;
                this.listDataMasterRuleGeneralCheck.platform = resultDataRule.listDataMasterRuleGeneralCheck.platform;
                this.listDataMasterRuleClose = resultDataRule.listDataMasterRuleClose;
                this.listDataMasterRuleCloseCheck.platform = resultDataRule.listDataMasterRuleCloseCheck.platform;
                this.dataRuleTab2 = resultDataRule.dataRuleTab2;
                this.dataRuleTab2Check = resultDataRule.dataRuleTab2Check;

                this.loading = false;
            });
        },

        changeTab() {
            this.modalConditionOtherObj[this.modalConditionOtherId].campaignType = this.campaignType;
            this.dataRuleTab2Check = JSON.parse(JSON.stringify(this.dataRuleTab2));
            this.listDataMasterCampaignType.forEach((item) => {
                if (item.value == this.modalConditionOtherObj[this.modalConditionOtherId].campaignType) {
                    this.listTab2 = item.data;
                }
            });
            this.clearDataOther();
        },

        getListMasterTemplate() {
            this.loading = true;
            this.templateList().then((data) => {
                if (data.code == 200) {
                    this.listCondition.forEach((item) => {
                        if (item.label == '当選配信文言') {
                            data.data.forEach((jtem) => {
                                jtem.source = jtem.id;
                                item.data.push(jtem);
                                jtem.id = jtem.id + 'c';
                            });
                        }
                    });
                }
                this.loading = false;
            });
        },

        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        getListMasterCampaign() {
            this.loading = true;
            this.getAllDocuments().then((data) => {
                if (data.code == 200) {
                    this.listDataMasterCampaign = data.data;
                    if (this.$route.query.id) {
                        let index = this.listDataMasterCampaign.findIndex((x) => {
                            return x.id == this.$route.query.id;
                        });
                        if (index >= 0) {
                            this.campaign = this.listDataMasterCampaign[index];
                        }
                    } else if (this.currentUser.default_campaign) {
                        let index = this.listDataMasterCampaign.findIndex((x) => {
                            return x.id == this.currentUser.default_campaign.id;
                        });
                        if (index >= 0) {
                            this.campaign = this.listDataMasterCampaign[index];
                        }
                    }
                }
                this.loading = false;
            });
        },

        getListMasterCampaignDetail() {
            this.isLoadingConditionOther = true;
            this.getDetailDocuments(this.campaign).then((data) => {
                if (data.code == 200) {
                    const dataRes = data?.data ?? [];
                    this.detailBeginForDocument = dataRes;
                    this.dataRuleTab = [];
                    this.campaignType = dataRes.campaign_type ? dataRes.campaign_type.value : '';

                    if (dataRes.campaign_type) {
                        this.listDataMasterCampaignType.forEach((item) => {
                            if (item.id == dataRes.campaign_type.id) {
                                this.listTab2 = item.data;
                            }
                        });
                    }

                    dataRes.categories.forEach((item) => {
                        this.dataRuleTab.push(item.category_id);
                    });

                    this.listDataMasterClients = dataRes.clients?.map((itemCl) => {
                        itemCl.client = {
                            ...itemCl.client,
                            client_id: itemCl?.client?.id ?? '',
                            client_name: itemCl?.client?.name ?? ''
                        };
                        return itemCl.client;
                    });

                    this.listDataMasterPartners =
                        dataRes?.partner_shipping_type?.map((item) => {
                            return {
                                id: item.id,
                                name: item.name
                            };
                        }) ?? [];
                }
                this.isLoadingConditionOther = false;
            });
        },

        getListMasterScheduleFile() {
            this.loading = true;
            this.getListScheduleFile()
                .then((dataRespone) => {
                    this.listDataType1 = dataRespone.data.filter((item) => {
                        if (item.type === 1) {
                            item.works.forEach((ws) => {
                                ws.checked = false;
                            });
                            return item;
                        }
                    });
                    this.listDataPlatform = dataRespone.data.filter((item) => {
                        if (item.type === 2) {
                            item.works.forEach((ws) => {
                                ws.checked = false;
                            });
                            return item;
                        }
                    });
                    this.loading = false;
                })
                .catch((error) => {
                    console.log('error', error);
                });
        },

        setData(label, data, key, jey) {
            this.indexActive = key;
            switch (label) {
                case this.$t('document.schedule'):
                    if (this.objExportSchedule && this.objExportSchedule.campaign_start_date) {
                        this.detailBeginForDocument = JSON.parse(JSON.stringify(this.detailBeginForDocument));
                        this.dataDetailForDocument.campaign_start_date = this.objExportSchedule.campaign_start_date;
                        this.dataDetailForDocument.campaign_end_date = this.objExportSchedule.campaign_end_date;
                        this.dataDetailForDocument.secretariat_end_date = this.objExportSchedule.secretariat_end_date;
                        this.configExportSchedule.dataDocument.categories = this.objExportSchedule.categories;
                    } else {
                        this.dataDetailForDocument = this.detailBeginForDocument;
                    }
                    this.configExportSchedule.id = label;
                    this.configExportSchedule.code = data.id;
                    this.configExportSchedule.model = true;
                    this.configExportSchedule.dataCPType = this.listDataMasterCampaignType;
                    this.configExportSchedule.dataDocument = this.dataDetailForDocument;
                    this.configExportSchedule.dateShipping = this.objExportSchedule?.date_shipping;
                    this.configExportSchedule.listIDWorks = this.objExportSchedule?.titles ?? [];

                    this.$bvModal.show(label);
                    break;
                case this.$t('document.greetingCard'):
                    if (!this.detailBeginForDocument?.event_prizes || this.detailBeginForDocument.event_prizes.length <= 0) {
                        showMessage(8080, this.$bvToast, this.$t('document.msgGreetingCard'));
                        return;
                    }
                    this.configModalCommonCondition.id = `${key}-${jey}`;
                    this.configModalCommonCondition.model = true;
                    this.configModalCommonCondition.typeDoc = '';
                    break;
                case this.$t('document.manual'):
                    this.configModalCommonCondition.id = `${key}-${jey}`;
                    this.configModalCommonCondition.model = true;
                    this.configModalCommonCondition.typeDoc = 'document-manual';
                    break;
                case this.$t('document.mumberOfMessagesFMT'):
                    if (!this.detailBeginForDocument.campaign_start_date || !this.detailBeginForDocument.campaign_end_date) {
                        showMessage(8080, this.$bvToast, this.$t('document.msgFMT'));
                        return;
                    }
                    data.conditions.campaign_start_date = this.detailBeginForDocument.campaign_start_date;
                    data.conditions.campaign_end_date = this.detailBeginForDocument.campaign_end_date;
                    data.conditions.categories = this.detailBeginForDocument.categories;

                    this.configModalCommonConditionFMT.id = `${key}-${jey}`;
                    this.configModalCommonConditionFMT.model = true;
                    break;
                default:
                    break;
            }
        },

        async handleGetRuleSavePayLoad(dataSource, selectSource) {
            this.isLoadingConditionOther = true;
            try {
                const data = await this.getRuleSavePayLoad(dataSource);
                let dataResponse = data.data;
                if (!dataResponse?.id) {
                    this.dataRuleTab2Check = JSON.parse(JSON.stringify(this.dataRuleTab2));
                    return;
                }
                this.campaignType = dataResponse.campaign_type ? dataResponse.campaign_type.value : '';
                this.dataRuleTab = dataResponse.categories;
                this.modalConditionOtherObj[selectSource.id].categories = dataResponse.categories;
                this.modalConditionOtherObj[selectSource.id].campaignType = dataResponse.campaign_type ? dataResponse.campaign_type.value : '';

                if (dataResponse?.client_id?.id) {
                    selectSource.client = {
                        client_id: dataResponse?.client_id?.id ?? null,
                        client_name: dataResponse?.client_id?.name ?? ''
                    };
                }
                this.listConditionRuleDetail.forEach((masterRule) => {
                    if (dataResponse.rules.includes(masterRule.id)) {
                        if (masterRule.type === 1 || masterRule.type === 2) {
                            if (!this.listDataMasterRuleGeneralCheck.rules.includes(masterRule.id)) {
                                this.listDataMasterRuleGeneralCheck.rules.push(masterRule.id);
                                this.listDataMasterRuleCloseCheck.rules.push(masterRule.id);
                            }
                            if (!selectSource.rulesCheckTab1.includes(masterRule.id)) {
                                selectSource.rulesCheckTab1.push(masterRule.id);
                            }
                        } else {
                            if (!selectSource.rulesCheckTab2.includes(masterRule.id)) {
                                selectSource.rulesCheckTab2.push(masterRule.id);
                            }

                            if (!this.listIDConditionTab2Checked.includes(masterRule.id)) {
                                this.listIDConditionTab2Checked.push(masterRule.id);
                            }
                        }
                    }
                });

                this.listDataMasterCampaignType.forEach((item) => {
                    if (item.value == this.modalConditionOtherObj[selectSource.id].campaignType) {
                        this.listTab2 = item.data;
                    }
                });

                this.listTab2.forEach((item) => {
                    if (this.modalConditionOtherObj[selectSource.id].categories.includes(item.id)) {
                        if (this.dataRuleTab2Check[item.id]) {
                            this.dataRuleTab2Check[item.id].forEach((item1) => {
                                if (selectSource.rulesCheckTab2.includes(item1.id)) item1.rules = [item1.id];
                            });
                        } else {
                            if (this.dataRuleTab2[item.id]) {
                                this.dataRuleTab2Check[item.id].forEach((item1) => {
                                    if (selectSource.rulesCheckTab2.includes(item1.id)) item1.rules = [item1.id];
                                });
                            }
                        }
                    }
                });

                this.handleGetDetailConditionsTab1(selectSource.rulesCheckTab1);
                this.handleGetDetailConditionsTab2(selectSource.rulesCheckTab2, null);
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoadingConditionOther = false;
            }
        },

        async handleSaveRulePayLoad(dataPayload) {
            if (this.isLoadingConditionOther) return;
            this.isLoadingConditionOther = true;
            try {
                const data = await this.postRuleSavePayLoad(dataPayload);
                if (data.code == 200) {
                    showMessage(data.code, this.$bvToast, '更新されました。');
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoadingConditionOther = false;
            }
        },

        async setDataOther(data, key, jey) {
            this.indexActive = key;
            this.modalConditionOtherId = data.id;
            this.modalConditionOtherObj[this.modalConditionOtherId] = data;
            let dataSource = {
                campaign_id: this.detailBeginForDocument.id,
                source: data.source
            };
            this.configModalCommonConditionOther.id = `${key}-${jey}`;
            this.configModalCommonConditionOther.model = true;
            await this.handleGetRuleSavePayLoad(dataSource, data);
            this.listDataMasterRuleCloseCheck.platform = JSON.parse(JSON.stringify(this.listDataMasterRuleClose));
            this.listDataMasterRuleGeneralCheck.platform = JSON.parse(JSON.stringify(this.listDataMasterRuleGeneral));
        },
        async setConditionRule(dataForm) {
            let clientId = dataForm?.client?.id ?? dataForm?.client?.client_id ?? null;
            if (!clientId) return;
            this.modalConditionOtherObj[this.modalConditionOtherId].categories = this.dataRuleTab;
            let itemConditionSave = this.modalConditionOtherObj[this.modalConditionOtherId];

            dataForm.rules = itemConditionSave.rulesCheckTab1.concat(itemConditionSave.rulesCheckTab2);
            let payload = {
                campaign_id: this.detailBeginForDocument.id,
                source: itemConditionSave.source,
                data: {
                    client_id: clientId ?? null,
                    rules: itemConditionSave.rulesCheckTab1.concat(itemConditionSave.rulesCheckTab2),
                    campaign_type: this.listDataMasterCampaignType.find((x) => {
                        if (itemConditionSave.campaignType) {
                            return x.value == itemConditionSave.campaignType;
                        } else {
                            return x.value == this.campaignType;
                        }
                    }).id,
                    categories: itemConditionSave.categories
                }
            };
            await this.handleSaveRulePayLoad(payload);
            this.clearDataOther();
        },

        register() {
            this.handleRemoveCampaign();
            let resultMap = handMapDataDocument(this.listCondition, this.listNotCondition, this.conditions);
            let objectData = {
                campaign_id: this.campaign?.id ?? null,
                documents: resultMap.documents
            };
            if (!resultMap.check) {
                showMessage(8080, this.$bvToast, '出力条件を設定してください。');
            }
            if (this.loading || !this.campaign?.id || !resultMap.check) return;
            this.loading = true;
            this.postDocuments(objectData).then((data) => {
                if (data.code == 200) {
                    showMessage(data.code, this.$bvToast, '登録されました。');
                    this.objExportSchedule = null;
                } else {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                }
                this.loading = false;
            });
        },

        handleTabClick(tabNumber) {
            this.activeTab = tabNumber;
        },
        goToEdit(path) {
            this.$router.push({ path: path });
        },

        async showPreview(id) {
            this.templateDetail = {};
            this.configModalCommonPreview.id = id;
            this.configModalCommonPreview.model = true;
            await this.getTemplateDetail(id);
            await this.getListMasterTemplate2();
        },

        setPreview(data, key, jey) {
            if (!this.campaign?.id) return;
            this.configFaq.listData = {};
            this.configFaq.listTitle = {};
            this.configFaq.listFAQ = {};
            this.configFaq.id = `${key}-${jey}`;
            this.configFaq.model = true;
            this.configFaq.isLoadingPreviewFAQ = true;
            this.getDocumentFAQ(this.campaign).then((data) => {
                if (data.code == 200) {
                    data.data.forEach((item) => {
                        if (this.configFaq.listData && !this.configFaq.listData[item.sheet_name]) {
                            this.configFaq.listData[item.sheet_name] = [];
                        }
                        item.answers.forEach((aTem) => {
                            if (!this.configFaq.listTitle[item.sheet_name]) this.configFaq.listTitle[item.sheet_name] = {};
                            if (this.configFaq.listTitle[item.sheet_name] && !this.configFaq.listTitle[item.sheet_name][aTem.category_id])
                                this.renderNameTitle(item.sheet_name, aTem.category_id);
                        });
                        if (Array.isArray(this.configFaq.listData[item.sheet_name])) {
                            this.configFaq.listData[item.sheet_name].push(item);
                        }
                    });
                    setTimeout(() => {
                        data.data.forEach((item) => {
                            if (Array.isArray(this.configFaq.listData[item.sheet_name])) {
                                this.configFaq.listData[item.sheet_name] = this.configFaq.listData[item.sheet_name].reduce((acc, curr) => {
                                    const { question_id } = curr;
                                    if (!acc[question_id]) {
                                        acc[question_id] = {
                                            campaign_id: curr.campaign_id,
                                            id: curr.id,
                                            question: replaceQ(curr.question, this.detailBeginForDocument),
                                            question_id: curr.question_id,
                                            sheet_id: curr.sheet_id,
                                            answers: []
                                        };
                                    }
                                    acc[question_id].answers = acc[question_id].answers.concat(replaceA(curr.answers, this.detailBeginForDocument));
                                    return acc;
                                }, {});
                            }
                        });
                        this.configFaq.isLoadingPreviewFAQ = false;
                    }, 1000);
                }
            });
            this.campaign.campaign_id = this.campaign?.id ?? null;
            this.campaign.source = 'faq';
            this.postDocumentFAQPreview(this.campaign).then((data) => {
                if (data.code == 200) {
                    let context_data = data.data.context_data;
                    this.postDocumentFAQ(this.campaign).then((dat) => {
                        if (dat.code == 200) {
                            for (const [key, item] of Object.entries(dat.data)) {
                                item.forEach((i, k) => {
                                    dat.data[key][k] = dat.data[key][k].replace(/《キャンペーン名》/g, context_data.name);
                                    if (context_data.campaign_start_date)
                                        dat.data[key][k] = dat.data[key][k].replace(
                                            /《キャンペーン開始》/g,
                                            moment(context_data.campaign_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                                        );
                                    if (context_data.tel) dat.data[key][k] = dat.data[key][k].replace(/《キャンペーン事務局TEL》/g, context_data.tel);
                                    if (context_data.email)
                                        dat.data[key][k] = dat.data[key][k].replace(/《キャンペーン事務局Mailアドレス》/g, context_data.email);
                                    if (context_data.secretariat_start_date)
                                        dat.data[key][k] = dat.data[key][k].replace(
                                            /《事務局開始》/g,
                                            moment(context_data.secretariat_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                                        );
                                    if (context_data.secretariat_end_date)
                                        dat.data[key][k] = dat.data[key][k].replace(
                                            /《事務局終了》/g,
                                            moment(context_data.secretariat_end_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                                        );
                                    if (context_data.year_end_schedule_from || context_data.year_end_schedule_to)
                                        dat.data[key][k] = dat.data[key][k].replace(
                                            /《年末年始休み》/g,
                                            `(${moment(context_data.year_end_schedule_from).locale('ja').format('YYYY年MM月DD日（ddd）')} ~ ${moment(
                                                context_data.year_end_schedule_to
                                            )
                                                .locale('ja')
                                                .format('YYYY年MM月DD日（ddd）')})`
                                        );
                                });
                            }
                            this.configFaq.listFAQ = JSON.parse(JSON.stringify(dat.data));
                        }
                    });
                }
            });
        },

        renderNameTitle(sheetName, category_id) {
            this.listDataMasterCampaignType.forEach((item) => {
                item.data.forEach((aTem) => {
                    if (category_id == aTem.id) {
                        this.configFaq.listTitle[sheetName][category_id] = aTem.value;
                    }
                });
            });
        },
        async getTemplateDetail(id) {
            let template = {
                id: id ? id.replace('c', '') : ''
            };
            this.isLoadingTemplate = true;
            try {
                const data = await this.templateGetDetail(template);
                if (data.code !== 200 || !data?.data) return;
                this.templateDetail = data.data;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoadingTemplate = false;
            }
        },

        async getListMasterTemplate2() {
            let campaign = {
                campaign_id: this.campaign?.id ?? null
            };
            this.isLoadingTemplate = true;
            try {
                const data = await this.documentPreview(campaign);
                if (data.code !== 200) return;
                this.documentPreviewData = data.data.context_data;
                let data1 = JSON.parse(JSON.stringify(this.templateDetail.content));
                if (this.documentPreviewData.name) data1 = data1.replace(/《キャンペーン名》/g, this.documentPreviewData.name);
                if (this.documentPreviewData.campaign_start_date)
                    data1 = data1.replace(
                        /《キャンペーン開始》/g,
                        moment(this.documentPreviewData.campaign_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                    );
                if (this.documentPreviewData.tel) data1 = data1.replace(/《キャンペーン事務局TEL》/g, this.documentPreviewData.tel);
                if (this.documentPreviewData.email) data1 = data1.replace(/《キャンペーン事務局Mailアドレス》/g, this.documentPreviewData.email);
                if (this.documentPreviewData.secretariat_start_date)
                    data1 = data1.replace(
                        /《事務局開始》/g,
                        moment(this.documentPreviewData.secretariat_start_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                    );
                if (this.documentPreviewData.secretariat_end_date)
                    data1 = data1.replace(
                        /《事務局終了》/g,
                        moment(this.documentPreviewData.secretariat_end_date).locale('ja').format('YYYY年MM月DD日（ddd）')
                    );
                if (this.documentPreviewData.year_end_schedule_from || this.documentPreviewData.year_end_schedule_to)
                    data1 = data1.replace(
                        /《年末年始休み》/g,
                        `(${moment(this.documentPreviewData.year_end_schedule_from).locale('ja').format('YYYY年MM月DD日（ddd）')} ~ ${moment(
                            this.documentPreviewData.year_end_schedule_to
                        )
                            .locale('ja')
                            .format('YYYY年MM月DD日（ddd）')})`
                    );
                this.templateDetail.content = JSON.parse(JSON.stringify(data1));
            } catch (error) {
                console.log('error', error);
            } finally {
                this.isLoadingTemplate = false;
            }
        },

        handleGetDetailConditionsTab1(listIdCheck) {
            this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab1 = listIdCheck;
            this.listChildConditionAfterCheckedTab1 = [];
            this.listConditionRuleDetail.forEach((jtem) => {
                if (listIdCheck.includes(jtem.id)) {
                    this.listChildConditionAfterCheckedTab1.push({
                        textParent: jtem.rule,
                        id: jtem.id,
                        details: jtem.details,
                        backgroundColor: '',
                        color: ''
                    });
                }
            });
        },

        handleSelectTextTab1(idText) {
            if (this.listChildConditionAfterCheckedTab1.length <= 0) return;
            this.listChildConditionAfterCheckedTab1.forEach((item) => {
                if (item.id !== idText) {
                    item.backgroundColor = '';
                    item.color = '';
                } else {
                    item.backgroundColor = '#b7b7b74d';
                    item.color = '#3e3e3e';
                }
            });
        },

        handleGetDetailConditionsTab2(listIdCheck, typeHandle) {
            if (typeHandle === 'model_checked') {
                if (!this.listIDConditionTab2Checked.includes(listIdCheck.id)) {
                    this.listIDConditionTab2Checked.push(listIdCheck.id);
                } else {
                    this.listIDConditionTab2Checked = this.listIDConditionTab2Checked.filter((item) => item !== listIdCheck.id);
                }
            }
            this.modalConditionOtherObj[this.modalConditionOtherId].rulesCheckTab2 =
                typeHandle === 'model_checked' ? this.listIDConditionTab2Checked : listIdCheck;

            this.listChildConditionAfterCheckedTab2 = [];
            this.listConditionRuleDetail.forEach((jtem) => {
                if (this.listIDConditionTab2Checked.includes(jtem.id)) {
                    this.listChildConditionAfterCheckedTab2.push({
                        textParent: jtem.rule,
                        id: jtem.id,
                        details: jtem.details,
                        backgroundColor: '',
                        color: ''
                    });
                }
            });
        },

        handleSelectTextTab2(idText) {
            if (this.listChildConditionAfterCheckedTab2.length <= 0) return;
            this.listChildConditionAfterCheckedTab2.forEach((item) => {
                if (item.id !== idText) {
                    item.backgroundColor = '';
                    item.color = '';
                } else {
                    item.backgroundColor = '#b7b7b74d';
                    item.color = '#3e3e3e';
                }
            });
        },

        handleChangeCategoryTab2(listCategory) {
            this.modalConditionOtherObj[this.modalConditionOtherId].categories = listCategory;
            this.listTab2.forEach((item) => {
                if (!listCategory.includes(item.id)) {
                    this.dataRuleTab2Check[item.id]?.forEach((item1) => {
                        item1.rules = [];
                        this.listIDConditionTab2Checked = this.listIDConditionTab2Checked.filter((item2) => item2 !== item1.id);
                    });
                }
            });
            this.handleGetDetailConditionsTab2(this.listIDConditionTab2Checked, null);
        },

        isActivePopUpCondition(idActive, type) {
            switch (type) {
                case 'condition':
                    return idActive === this.configModalCommonCondition.id;
                case 'condition-fmt':
                    return idActive === this.configModalCommonConditionFMT.id;
                case 'condition-other':
                    return idActive === this.configModalCommonConditionOther.id;
                case 'faq-preview':
                    return idActive === this.configFaq.id;
            }
        },

        handleRemoveCampaign() {
            if (!this.configSelectCampaign?.valueSync?.id) {
                this.configSelectCampaign.error = true;
            }
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 custom--tabss">
                                <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" v-model="activeTab">
                                    <b-tab @click="handleTabClick(0)" class="border-0">
                                        <template v-slot:title>
                                            <span class="">一覧</span>
                                        </template>

                                        <div>
                                            <div class="form-group row">
                                                <label class="col-sm-3">キャンペーン名 <span class="text-danger">*</span></label>
                                                <div class="col-sm-8">
                                                    <div class="d-flex">
                                                        <div class="col-sm-5 p-0">
                                                            <Multiselect
                                                                :id="`select-campaign`"
                                                                :value.sync="campaign"
                                                                :options="listDataMasterCampaign"
                                                                :config.sync="configSelectCampaign"
                                                                @remove="handleRemoveCampaign"
                                                            />
                                                            <div v-if="configSelectCampaign.error" class="d-block invalid-feedback">
                                                                {{ configSelectCampaign.errorMsg }}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button
                                                                @click="goToEdit('/campaign/list')"
                                                                class="btn btn-primary ml-3 mb-0"
                                                                style="margin-bottom: 1px; min-width: 80px"
                                                            >
                                                                変更
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="table-responsive">
                                                <table class="table mb-0 table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>項目</th>
                                                            <th>ファイル名</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="2">条件設定なし</td>
                                                        </tr>
                                                        <tr v-for="(item, key) in listNotCondition" :key="`condition${key}`">
                                                            <td>{{ item.label }}</td>
                                                            <td>
                                                                <div class="d-flex align-items-center mb-1" v-for="item in item.data" :key="item.id">
                                                                    <InputCheckBox
                                                                        :model.sync="conditions[item.id]"
                                                                        :id="`checks${item.id}`"
                                                                        :value="item.source"
                                                                        :label="item.name"
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">条件設定あり</td>
                                                        </tr>
                                                        <tr v-for="(item, key) in listCondition" :key="`conditiond${key}`">
                                                            <td>{{ item.label }}</td>
                                                            <td>
                                                                <div
                                                                    class="d-flex align-items-center item2"
                                                                    style="border-bottom: 1px solid #efefef; padding-bottom: 5px"
                                                                    v-for="(jtem, jey) in item.data"
                                                                    :key="jtem.id"
                                                                >
                                                                    <div style="width: 100%">
                                                                        <div v-if="!item.isCheckbox">
                                                                            <InputCheckBox
                                                                                :model.sync="conditions[jtem.id]"
                                                                                :id="`checks${jtem.id}`"
                                                                                :value="jtem.source"
                                                                                :label="jtem.name"
                                                                            />
                                                                            <div
                                                                                v-if="conditions[jtem.id] && conditionLabel(item.label)"
                                                                                class="error"
                                                                            >
                                                                                <span v-if="!jtem.client && !jtem?.conditions?.campaign_start_date">
                                                                                    出力条件を設定してください。
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div v-else>
                                                                            <p class="custom-control font-weight-bold mb-0">
                                                                                {{ jtem.name }}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <button
                                                                        :disabled="!campaign?.id"
                                                                        v-if="jtem.preview"
                                                                        @click="setPreview(jtem, key, jey)"
                                                                        class="btn btn-sm btn-outline-primary"
                                                                        style="min-width: 90px"
                                                                    >
                                                                        プレビュー
                                                                    </button>

                                                                    <template v-else>
                                                                        <button
                                                                            v-if="item.label == '規約'"
                                                                            :disabled="!conditions[jtem.id] || !campaign?.id"
                                                                            @click="setDataOther(jtem, key, jey)"
                                                                            class="btn btn-sm btn-outline-info"
                                                                            style="min-width: 70px"
                                                                        >
                                                                            設定
                                                                        </button>
                                                                        <button
                                                                            v-else-if="item.label == $t('document.partnerRequestSheet')"
                                                                            :disabled="!campaign?.id"
                                                                            @click="
                                                                                () => {
                                                                                    $router.push({
                                                                                        path: `${jtem.path}?id=${campaign?.id}`
                                                                                    });
                                                                                }
                                                                            "
                                                                            class="btn btn-sm btn-outline-info"
                                                                            style="min-width: 70px"
                                                                        >
                                                                            {{ $t('btn.detail') }}
                                                                        </button>
                                                                        <button
                                                                            v-else-if="item.label != '当選配信文言'"
                                                                            :disabled="!conditions[jtem.id] || !campaign?.id"
                                                                            @click="setData(item.label, jtem, key, jey)"
                                                                            class="btn btn-sm btn-outline-info"
                                                                            style="min-width: 70px"
                                                                        >
                                                                            設定
                                                                        </button>
                                                                        <button
                                                                            v-else
                                                                            :disabled="!campaign?.id"
                                                                            @click="showPreview(jtem.id)"
                                                                            class="btn btn-sm btn-outline-primary"
                                                                            style="min-width: 90px"
                                                                        >
                                                                            プレビュー
                                                                        </button>

                                                                        <PopUpConditionFMT
                                                                            v-if="isActivePopUpCondition(`${key}-${jey}`, 'condition-fmt')"
                                                                            :id="`${key}${jey}`"
                                                                            :dataCondition.sync="jtem.conditions"
                                                                            :config="configModalCommonConditionFMT"
                                                                        />

                                                                        <PopUpCondition
                                                                            v-if="isActivePopUpCondition(`${key}-${jey}`, 'condition')"
                                                                            :id="`${key}${jey}`"
                                                                            :clientCondition.sync="jtem.client"
                                                                            :clientConditionType.sync="jtem.client_type"
                                                                            :partnerCondition.sync="jtem.partner"
                                                                            :dataMasterClients="listDataMasterClients"
                                                                            :dataMasterPartners="listDataMasterPartners"
                                                                            :config="configModalCommonCondition"
                                                                        />

                                                                        <PopUpConditionOther
                                                                            v-if="isActivePopUpCondition(`${key}-${jey}`, 'condition-other')"
                                                                            :dataRuleTab.sync="dataRuleTab"
                                                                            :dataRuleTab2="dataRuleTab2"
                                                                            :dataRuleTab2Check="dataRuleTab2Check"
                                                                            :childConditionAfterCheckedTab1.sync="listChildConditionAfterCheckedTab1"
                                                                            :childConditionAfterCheckedTab2.sync="listChildConditionAfterCheckedTab2"
                                                                            :campaignType.sync="campaignType"
                                                                            :listTab2="listTab2"
                                                                            :masterCampaignType="listDataMasterCampaignType"
                                                                            :masterRuleGeneral="listDataMasterRuleGeneral"
                                                                            :masterRuleGeneralCheck="listDataMasterRuleGeneralCheck"
                                                                            :masterRuleClose="listDataMasterRuleClose"
                                                                            :masterRuleCloseCheck="listDataMasterRuleCloseCheck"
                                                                            :masterClients="listDataMasterClients"
                                                                            :formOther="jtem"
                                                                            :loading="isLoadingConditionOther"
                                                                            :config="configModalCommonConditionOther"
                                                                            @changeCampaign="changeTab"
                                                                            @selectTextTab1="handleSelectTextTab1"
                                                                            @getDetailConditionsTab1="handleGetDetailConditionsTab1"
                                                                            @changeCategoryTab2="handleChangeCategoryTab2"
                                                                            @getDetailConditionsTab2="handleGetDetailConditionsTab2"
                                                                            @selectTextTab2="handleSelectTextTab2"
                                                                            @saveConditionRule="setConditionRule"
                                                                            @close="clearDataOther"
                                                                        />
                                                                    </template>

                                                                    <PopUpFAQ
                                                                        v-if="isActivePopUpCondition(`${key}-${jey}`, 'faq-preview')"
                                                                        :config.sync="configFaq"
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="text-center mt-4 position-relative"></div>
                                    </b-tab>
                                    <b-tab @click="handleTabClick(1)" class="border-0">
                                        <template v-slot:title>
                                            <span class="">出力結果</span>
                                        </template>

                                        <div>
                                            <HistoryExportDocument :activeTab="activeTab" />
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            v-if="activeTab !== 1"
                            type="button"
                            @click="register()"
                            class="btn btn-primary ml-3"
                            :class="`${isBtnExport ? 'disabled_cursor' : ''} `"
                            :disabled="isBtnExport"
                        >
                            出力
                        </button>
                    </div>
                </div>
            </Footer>

            <PopUpExportSchedule
                :config="configExportSchedule"
                :exportSchedule.sync="objExportSchedule"
                :masterDataType1="listDataType1"
                :masterDataPlatform="listDataPlatform"
            />

            <PopUpWinningDistribution :dataTemplateDetail="templateDetail" :loading="isLoadingTemplate" :config="configModalCommonPreview" />
        </div>

        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
.item2 {
    padding-top: 5px;
}
.item2:last-child {
    border: 0 !important;
}
.item2:hover {
    background: rgba(239, 239, 239, 0.28);
}
</style>
