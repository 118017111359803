<template>
    <div>
        <div class="form-group row">
            <div class="col-sm-8 ml-0">
                <div class="d-flex align-items-center">
                    <button
                        class="btn btn-primary"
                        @click="
                            page = 1;
                            getHistory();
                        "
                    >
                        <i class="ri-refresh-line"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table mb-0 table-bordered table-box">
                <thead>
                    <tr>
                        <th>ファイル名</th>
                        <th>ステータス</th>
                        <th>形式</th>
                        <th>時間</th>
                        <th>サイズ</th>
                    </tr>
                </thead>
                <tbody v-if="historyListDocument.length > 0 && isLoadingTable">
                    <tr v-for="item in historyListDocument" :key="item.id">
                        <td class="text-left">
                            <div class="row-data-medium">
                                <a target="_blank" :href="item.url" v-b-tooltip.hover :title="item?.name ?? ''">{{ item.name }}</a>
                            </div>
                        </td>
                        <td>
                            <div class="">
                                <p class="text-center p-2 rounded" :style="`background-color: ${handleBgStatus(item.status)}; color:#ffff`">
                                    {{ $t(item.status) }}
                                </p>
                            </div>
                        </td>
                        <td>
                            <div class="row-data-small">
                                {{ item.file_type }}
                            </div>
                        </td>
                        <td>
                            <div class="row-data-small">
                                {{ getCurrentDateTime(item.created_at) }}
                            </div>
                        </td>
                        <td>
                            <div class="row-data-small">{{ item.size ? Number(item.size.toFixed(1)) : '0' }}mb</div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="!isLoadingTable">
                    <template>
                        <tr style="position: relative; height: 80px">
                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                <div class="d-flex justify-content-center">
                                    <LoadingIcon />
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <template>
                        <tr style="position: relative; height: 80px">
                            <td style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border: none">
                                <p class="mt-3 text-center">
                                    {{ $t('notificationCommon.searchEmpty') }}
                                </p>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <pagination v-if="total && historyListDocument.length > 0 && isLoadingTable" :total="total" :page.sync="page" :per-page="limit"></pagination>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

import { campaignMethods } from '@/state/helpers';

export default {
    components: {
        Pagination,
        LoadingIcon
    },
    props: {
        activeTab: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            total: 0,
            limit: 10,
            page: 1,
            isLoadingTable: true,
            historyListDocument: []
        };
    },
    methods: {
        ...campaignMethods,

        getHistory() {
            this.historyListDocument = [];
            this.isLoadingTable = false;
            let query = this.getQueryString();
            this.historyDocuments(query).then((data) => {
                if (data.code == 200) {
                    this.historyListDocument = data.data;
                    this.total = data._metadata.total;
                    this.limit = data._metadata.limit * 1;
                }

                this.isLoadingTable = true;
            });
        },
        getQueryString() {
            let query = [`page=${this.page}`, `limit=${this.limit}`, `sorts[id]=desc`];
            return query.join('&');
        },

        getCurrentDateTime(date) {
            var now = new Date(date);

            var year = now.getFullYear();
            var month = ('0' + (now.getMonth() + 1)).slice(-2);
            var day = ('0' + now.getDate()).slice(-2);

            var hours = ('0' + now.getHours()).slice(-2);
            var minutes = ('0' + now.getMinutes()).slice(-2);
            var seconds = ('0' + now.getSeconds()).slice(-2);
            var formattedDateTime = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;

            return formattedDateTime;
        },

        handleBgStatus(status) {
            switch (status) {
                case 'success':
                    return '#1cbb8c';
                case 'processing':
                    return '#fea217';
                case 'failed':
                    return '#ea4335';
            }
        }
    },
    watch: {
        limit: {
            deep: true,
            handler: function () {
                clearTimeout(this.retrieveListTimeout);
                this.retrieveListTimeout = setTimeout(() => {
                    this.getHistory();
                }, 500);
            }
        },
        page: {
            deep: true,
            handler: function () {
                this.getHistory();
            }
        },
        reload: function () {
            this.getHistory();
        },
        activeTab: {
            handler: function (tab) {
                if (tab == 1) this.getHistory();
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
