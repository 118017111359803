<template>
    <div>
        <ModalCommon :id="`configModalCommon-${id}`" :config="config" @save="setCondition()" @close="handleClear()">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-4" style="white-space: nowrap">エンドクライアント <span class="text-danger">*</span></label>
                    <div class="col-sm-8">
                        <Multiselect
                            :id="`select-campaign`"
                            :value.sync="client"
                            :options="listDataMasterClients"
                            :config.sync="configFieldCondition"
                        />
                    </div>
                </div>

                <div v-if="config?.typeDoc == 'document-manual'">
                    <div class="form-group row">
                        <label class="col-sm-4" style="white-space: nowrap" for="client_type"
                            >エンドクライアント (代理店) <span class="text-danger">*</span></label
                        >
                        <div class="col-sm-8">
                            <Multiselect
                                :id="`select-partner`"
                                :value.sync="client_type"
                                :options="optionsClientType"
                                :config.sync="configFieldConditionClientType"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4" style="white-space: nowrap" for="department">外部パートナー <span class="text-danger">*</span></label>
                        <div class="col-sm-8">
                            <Multiselect :id="`select-partner`" :value.sync="partner" :options="optionsPartner" :config.sync="configFieldPartner" />
                        </div>
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import Multiselect from '@/components/Multiselect/main.vue';
import ModalCommon from '@/components/Modal/main.vue';

import { validateField, validateAllField } from '@/utils/validate';

export default {
    name: 'ModalCondition',
    components: {
        Multiselect,
        ModalCommon
    },
    props: {
        id: {
            required: true
        },
        clientCondition: {
            type: Object,
            default: () => null
        },
        clientConditionType: {
            type: Object,
            default: () => null
        },
        partnerCondition: {
            type: Object,
            default: () => null
        },
        dataMasterClients: {
            required: true,
            type: Array,
            default: () => []
        },
        dataMasterPartners: {
            required: true,
            type: Array,
            default: () => []
        },
        config: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            client: null,
            configFieldCondition: {
                trackBy: 'client_id',
                label: 'client_name',
                error: false,
                errorPartner: '',
                isRemove: true,
                errorField: 'エンドクライアント',
                valueSync: null,
                isRequired: false,
                typesValidate: ['requiredSelect']
            },
            partner: null,
            configFieldPartner: {
                trackBy: 'id',
                label: 'name',
                error: false,
                errorPartner: '',
                isRemove: true,
                errorField: '外部パートナー',
                valueSync: null,
                isRequired: false,
                typesValidate: ['requiredSelect']
            },
            client_type: null,
            configFieldConditionClientType: {
                trackBy: 'id',
                label: 'name',
                error: false,
                errorPartner: '',
                isRemove: true,
                errorField: 'エンドクライアント (代理店)',
                valueSync: null,
                isRequired: false,
                typesValidate: ['requiredSelect']
            },
            listDataMasterClients: [],
            optionsPartner: [],
            optionsClientType: []
        };
    },
    methods: {
        setCondition() {
            if (this.config?.typeDoc == 'document-manual') {
                let configs = {
                    configFieldCondition: this.configFieldCondition,
                    configFieldPartner: this.configFieldPartner,
                    configFieldConditionClientType: this.configFieldConditionClientType
                };
                let check = validateAllField(configs);
                if (!check) return;
            } else {
                let check = validateField(this.configFieldCondition);
                if (!check) return;
            }
            this.client = {
                client_id: this.client?.client_id ?? '',
                client_name: this.client?.client_name ?? ''
            };
            this.client_type = {
                client_type_agency_id: this?.client_type?.id ?? '',
                client_type_agency_name: this?.client_type?.name ?? ''
            };
            this.$emit('update:clientCondition', this.client);
            this.$emit('update:clientConditionType', this.client_type);
            this.$emit('update:partnerCondition', this.partner);
            this.config.model = false;
        },

        handleClear() {
            this.client = null;
            this.client_type = null;
            this.partner = null;
            this.configFieldCondition.error = false;
            this.configFieldPartner.error = false;
            this.configFieldConditionClientType.error = false;
            this.config.model = false;
            this.$emit('update:clientCondition', this.client);
            this.$emit('update:clientConditionType', this.client);
            this.$emit('update:partnerCondition', this.partner);
        }
    },
    watch: {
        dataMasterClients: {
            handler: function (newDataMasterClients) {
                if (newDataMasterClients?.length <= 0) return;
                this.listDataMasterClients = newDataMasterClients
                    .filter((item) => item.type_id !== 1)
                    .filter((item, index, self) => index === self.findIndex((i) => i.id === item.id));

                this.optionsClientType = newDataMasterClients
                    .filter((item) => item.type_id == 1)
                    .filter((item, index, self) => index === self.findIndex((i) => i.name === item.name));
            },
            immediate: true
        },

        dataMasterPartners: {
            handler: function (newDataMasterPartners) {
                this.optionsPartner = newDataMasterPartners.filter((item, index, self) => index === self.findIndex((i) => i.name === item.name));
            },
            immediate: true
        },

        clientCondition: {
            handler: function (newclient) {
                this.client = newclient;
            },
            immediate: true
        },

        clientConditionType: {
            handler: function (newclient) {
                if (newclient) {
                    this.client_type = {
                        id: newclient?.client_type_agency_id ?? '',
                        name: newclient?.client_type_agency_name ?? ''
                    };
                }
            },
            immediate: true
        },

        partnerCondition: {
            handler: function (newPartner) {
                this.partner = newPartner;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
